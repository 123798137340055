footer {
  background-color: hsl(194, 100%, 42%);
  height: 200px;
  grid-area: foot;
  display: flex;
  flex-flow: row wrap;
}

.footer-ad {
  flex-basis: 100%;
  height: auto;
  width: 80%;
  margin: 2em auto;
  grid-area: adspace;
  text-align: center;
}
footer ul {
  display: flex;
  flex-direction: row;
  justify-self: start;
  flex-grow: 100;

  width: 100%;
}
footer ul li {
  display: flex;
}

footer ul li a {
  font-size: 1em;
  color: white;
}
footer ul li a.navlink {
  color: black;
}
@media (min-width: 1200px) {
  footer ul li a {
    font-size: 0.8em;
    color: white;
  }
  .copyInfo {
    color: white;
    font-size: 0.6rem;
    margin: 0 auto;
  }
}
