$main-color: rgb(0, 166, 214);
$main-fontColor: black;
$sidebar-bgColor: #fbf5f5;

$heading-size: 5rem;
$standard-text: 1rem;
$big-text: 2.5rem;
.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.article-previews {
  width: 100%;
  padding: 20px;
}

.article-previews article {
  height: 300px;
  background-color: black;
  display: grid;
  grid-template-rows: 40% 60%;
}

.article-previews article span {
  background-color: white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.navbar-brand img {
  max-width: 290px;
}
#carouselHomeControls {
  height: 400px;
}

.carousel-inner {
  max-height: 400px;
}

.carousel {
  position: relative;
  max-height: 400px;
}
.carousel-caption {
  bottom: 0;
  top: 10%;
}

.card-top {
  height: 130px;
  border-bottom: solid thin rgb(246, 248, 246);
}
.card-text {
  max-height: 3em;
  overflow: hidden;
}
#secondary-page-heading {
  grid-area: heading;
  background-color: #00a6d7;
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-bottom: 40px solid black;
  position: relative;
  width: 100%;

  display: flex;
  height: 217px;
  h1 {
    color: white;
    justify-self: center;
    align-self: center;
    z-index: 100;
    font-size: 3em;
    margin-left: 3rem;
  }
  h2 {
    color: white;
    justify-self: center;
    align-self: center;
    z-index: 100;
    font-size: 3em;
    margin-left: 3rem;
  }
  img {
    position: absolute;
    z-index: 10;
    width: 100%;
    height: auto;
    max-height: 200px;
    min-height: 200px;
  }
  .bg-overlay {
    position: absolute;
    width: 100%;
    height: 200px;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 20;
  }
}
.carousel-caption button {
}
// .main-container {
//   display: grid;
//   width: 100%;
//   background-color: rgb(246, 248, 246);
//   grid-template-columns: 100%;
// }

.main-container {
  display: grid;
  width: 100%;
  background-color: rgb(246, 248, 246);
  grid-template-columns: 4fr 1fr;
  grid-template-areas:
    "heading heading"
    "body-content sidebar"
    "adspace sidebar"
    "foot foot";
}
#primary-page-heading {
  grid-area: heading;
  width: 100%;
}

.main-body {
  grid-area: body-content;
  padding-left: 0;
  padding-right: 0;
  .main-body-textarea {
    margin: 0 auto;
    width: 90%;
    margin-top: 20px;
    h2 {
      font-size: 1.4rem;
    }
    p {
      font-size: 0.7rem;
    }
  }
}

.sidebar {
  border: 1px solid black;
}

.side-item {
  width: 98%;
  height: 40px;
  background-color: rgb(246, 248, 246);

  border-bottom: #000 solid 1px;
}
.side-item:nth-of-type(even) {
  background-color: rgb(227, 229, 227);
}
.sidebar ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
li.side-item .main-body {
  border: 1px solid black;
}
.stub {
  background-color: red;
}
.button {
  display: inline-block;
  zoom: 1; /* zoom and *display = ie7 hack for display:inline-block */
  *display: inline;
  vertical-align: baseline;
  margin: 0 2px;
  outline: none;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  font: 14px/100% Arial, Helvetica, sans-serif;
  padding: 0.5em 2em 0.55em;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 0.5em;
  -moz-border-radius: 0.5em;
  border-radius: 0.2em;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.blue {
  color: #d9eef7;
  border: solid 1px #0076a3;
  background: #bce0ff;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#bce0ff),
    to(#0078a5)
  );
  background: -moz-linear-gradient(top, #00adee, #0078a5);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00adee', endColorstr='#0078a5');
}
.blue:hover {
  background: #007ead;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#0095cc),
    to(#00678e)
  );
  background: -moz-linear-gradient(top, #0095cc, #00678e);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0095cc', endColorstr='#00678e');
}
.blue:active {
  color: #80bed6;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#0078a5),
    to(#00adee)
  );
  background: -moz-linear-gradient(top, #0078a5, #00adee);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0078a5', endColorstr='#00adee');
}

footer {
  height: 80px;
  background-color: #000;
}

.slide-btn {
  position: relative;
  z-index: 100;
}

/* image styles*/
.img-showcase-full {
  max-width: 80%;
  margin: 1em auto;
  display: block;
}
.tech_article {
  margin: 0 0 4em 0;
}
