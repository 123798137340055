/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-width: 320px) {
  /* Styles */
  .card {
    margin-top: 20px;
  }

  #page-heading-section {
    height: 200px;
  }
  h2 {
    font-size: 12px;
  }
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-width: 768px) {
  /* Styles */
}

/* iPads (landscape) ----------- */
@media only screen and (min-width: 992px) {
  /* Styles */
}

/* iPads (portrait) ----------- */
@media only screen and (min-width: 768px) {
  /* Styles */
  html {
    font-size: 14px;
  }
}

/* Desktops and laptops ----------- */
@media only screen and (min-width: 1200px) {
  /* Styles */
  html {
    font-size: 22px;
  }
  .main-body {
    /*TODO: Double check size old:min-width:1024px*/
    min-height: 1200px;
  }
  .sidebar {
    display: inline;
  }
  .main-container {
    //* grid-template-columns: 80% auto;*/
  }
  h5 {
    font-size: 1.1rem;
  }
  h6 {
    font-size: 0.8rem;
  }

  p {
    font-size: 0.7rem;
  }

  .btn {
    font-size: 0.9rem;
  }
  .card-subtitle {
    margin-bottom: 0.6rem;
  }
}
