.side-item {
  width: 100%;
  height: 60px;
  background-color: #f6f8f6;
  border-bottom: #000 solid 1px;
  padding: 14px;
  font-size: 1.1em;
}
.side-item:hover {
  background-color: hsl(210, 71%, 63%);
  border-bottom: #000 solid 1px;
}

.sidebar {
  grid-area: sidebar;
  border-top: none;
}
