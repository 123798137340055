$form-element-width: 500px;

#contact-form {
  display: flex;
  flex-direction: column;
  margin: 80px;
  width: 500px;
  input[type="text"] {
    height: 30px;
    border: 1px solid #6db1ff;
    border-radius: 10px;
    width: $form-element-width;
    margin-bottom: 20px;
    padding: 20px 10px;
  }
  textarea {
    border: 1px soild blue;
    border-radius: 10px;
    width: $form-element-width;
    padding: 20px 10px;
    border: 1px solid #6db1ff;
  }
  input[type="submit"] {
    width: 150px;
    margin-top: 20px;
    font-size: 18px;
    color: black;
    align-self: flex-end;
  }
}
