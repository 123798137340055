html {
  font-size: 12px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.navbar {
  padding: 0;
}

.card-subtitle {
  color: rgb(197, 196, 196);
}
.card-img-top {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.card-top {
  position: relative;
  width: 100;
  height: 100px;
  overflow: hidden;
}
.card {
  overflow: hidden;
}

.header-secondary {
}
.sidebar {
  display: none;
}
.center-banner {
  text-align: center;
  margin-top: 15px;
}
