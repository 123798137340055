.link-group4 {
  list-style: none;
  justify-content: space-around;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  h2 {
    font-size: 1rem;
    padding: 30px 30px;

    vertical-align: bottom;
  }
  .link-heading {
    width: 100%;
    height: auto;
    border-radius: 0;
    border-top: 1px solid grey;
    border-bottom: 1px solid grey;
    margin: 20px 0;
    text-align: center;
  }

  .list-group-item {
    min-width: 300px;
    max-width: 500px;
    width: 33%;
    flex-grow: 2;
    flex-shrink: 1;
    overflow: hidden;
    height: 200px;
    margin-bottom: 10px;
  }
  .list-group-item p {
    margin: 0;
    width: 100%;
  }
}
