.selection-filter {
  font-size: 1em;
  width: 40%;
  margin: 20px 10px;
}

.flex-main-body {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.flex-group {
  list-style-type: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  justify-content: space-evenly;
  .flex-group-items {
    flex-grow: 1;
    flex-basis: auto;
    display: grid;
    grid-template-columns: 33% 66%;
  }
  .flex-img {
    grid-column-start: 1;
  }
  .flex-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
  }
  .flex-group-text {
    grid-column-start: 2;
    padding: 0.75rem 1rem;
    overflow: hidden;
  }
  li {
    width: 28%;
    height: 200px;
    position: relative;
    display: block;

    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 10px;
    -webkit-animation-name: shadowAninOut;
    animation-name: shadowAninOut;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    overflow: hidden;
  }
  li:hover {
    z-index: 10;
    -webkit-animation-name: shadowAnin;
    animation-name: shadowAnin;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
  }
  h4 {
    font-size: 1rem;
    border-bottom: rgba(34, 48, 240, 0.308) dashed 1px;
  }

  .flex-group-items p {
    font-size: 0.7rem;
    width: 100%;
  }
}
.load-text {
  width: auto;
  margin: 0;
  padding: 0;
  font-size: 40px;
}
.load-text span {
  opacity: 1;
  width: 0;
  overflow: hidden;
  display: inline-block;
  margin: 0;
  padding: 0;
  position: relative;
  top: 0.25em;
  animation: example 3s linear infinite;
}

@keyframes example {
  0% {
    width: 0;
  }
  25% {
    width: 0.4em;
  }
  50% {
    width: 0.6em;
  }
  100% {
    width: 0.8em;
  }
}
