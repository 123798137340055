.navbar {
  padding: 0;
}

.navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
}
.navbar-expand-md .navbar-collapse {
  flex-direction: row-reverse;
}
@media (min-width: 768px) {
  .navbar-expand-md .navbar-collapse {
    margin-right: 20px;
  }
}
.navbar-nav a {
  font-size: 1.1rem;
}

@media (min-width: 1200px) {
  .navbar-nav a {
    font-size: 0.8rem;
  }
}
