.list-link {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  padding: 0;
  margin: 0;
}

.list-group-item {
  font-size: 2rem;
}

.two-list {
  width: 50%;
}

.link-group3 {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  li {
    width: 45%;
    height: 200px;
    border-radius: 10px;
    margin: 10px auto;
  }
  .list-group-item {
    z-index: 1;
    animation-name: shadowAninOut;
    animation-fill-mode: forwards;
    animation-duration: 1s;
    overflow: hidden;
    background-color: rgba(82, 79, 79, 0.03);
  }
}

.link-group {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  li {
    width: 33.33%;
    height: 200px;
    border-radius: 10px;
  }
  h4 {
    font-size: 1rem;
    border-bottom: rgba(34, 48, 240, 0.308) dashed 1px;
  }
  .list-group-item p {
    font-size: 0.7rem;
    width: 100%;
  }
  .list-group-item {
    z-index: 1;
    animation-name: shadowAninOut;
    animation-fill-mode: forwards;
    animation-duration: 1s;
    overflow: hidden;
    background-color: rgba(82, 79, 79, 0.03);
  }
}

.list-group-item p {
  font-size: 0.7rem;
  width: 50%;
}
.list-group-item:hover {
  z-index: 10;
  animation-name: shadowAnin;
  animation-fill-mode: forwards;
  animation-duration: 1s;
}
.list-group-item h4 {
  font-size: 1rem;
}
#page-heading-section {
  height: 400px;
  background-color: #00a6d7;
  display: flex;
  align-items: center;
  justify-content: center;
}

h2 {
  color: black;
}
.black-box-div {
  width: 300px;
  height: 100%;
  background-color: black;
}
.clear-box-div {
  width: 100px;
  height: 100%;
}
/* #### Mobile Phones Portrait #### */
@media screen and (max-device-width: 480px) and (orientation: portrait) {
  /* some CSS here */
}

/* #### Mobile Phones Landscape #### */
@media screen and (max-device-width: 640px) and (orientation: landscape) {
  /* some CSS here */
  #page-heading-section {
    height: 200px;
  }
  h2 {
    font-size: 12px;
  }
}

/* #### Mobile Phones Portrait or Landscape #### */
@media screen and (max-device-width: 640px) {
  /* some CSS here */
}

/* #### iPhone 4+ Portrait or Landscape #### */
@media screen and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
  /* some CSS here */
}

/* #### Tablets Portrait or Landscape #### */
@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  /* some CSS here */
}

/* #### Desktops #### */
@media screen and (min-width: 1024px) {
  /* some CSS here */
}

@keyframes shadowAnin {
  0% {
    box-shadow: 3px 3px 5px 6px rgba(222, 222, 222, 0);
    background-color: rgba(40, 201, 250, 0.02);
  }
  25% {
    box-shadow: 3px 3px 5px 6px rgba(222, 222, 222, 0.25);
    background-color: rgba(40, 201, 250, 0.04);
  }
  50% {
    box-shadow: 3px 3px 5px 6px rgba(222, 222, 222, 0.5);
    background-color: rgba(40, 201, 250, 0.06);
  }
  100% {
    box-shadow: 3px 3px 5px 6px rgba(222, 222, 222, 1);

    background-color: rgba(40, 201, 250, 0.09);
  }
}

@keyframes shadowAninOut {
  0% {
    box-shadow: 3px 3px 5px 6px rgba(222, 222, 222, 1);
    background-color: rgba(82, 79, 79, 0.03);
    z-index: 10;
  }
  25% {
    box-shadow: 3px 3px 5px 6px rgba(222, 222, 222, 0.5);
    background-color: rgba(82, 79, 79, 0.024);
    z-index: 8;
  }
  50% {
    box-shadow: 3px 3px 5px 6px rgba(222, 222, 222, 0.25);
    background-color: rgba(82, 79, 79, 0.015);
    z-index: 5;
  }
  100% {
    box-shadow: 3px 3px 5px 6px rgba(222, 222, 222, 0);
    z-index: 1;
  }
}
/* Events Page*/
.evts-bg {
  background-image: url(../img/events_bg.jpg);
}

.contact-bg {
  background-image: url(../img/contact_bg.jpg);
}

.resource-bg {
  background-image: url(../img/resources_bg.jpg);
}

.about-bg {
  background-image: url(../img/about_bg.jpg);
}
.film-bg {
  background-image: url(../img/events_bg.jpg);
}
.bg-img {
  background-position: center;
  background-size: cover;
}

/*animations*/
@keyframes shadowAnin2 {
  0% {
    box-shadow: 3px 3px 5px 6px rgba(222, 222, 222, 0);
    background-color: rgba(82, 79, 79, 0.01);
  }
  25% {
    box-shadow: 3px 3px 5px 6px rgba(222, 222, 222, 0.25);
    background-color: rgba(82, 79, 79, 0.015);
  }
  50% {
    box-shadow: 3px 3px 5px 6px rgba(222, 222, 222, 0.5);
    background-color: rgba(82, 79, 79, 0.024);
  }
  100% {
    box-shadow: 3px 3px 5px 6px rgba(222, 222, 222, 1);
    background-color: rgba(82, 79, 79, 0.03);
  }
}

@keyframes shadowAninOut2 {
  0% {
    box-shadow: 3px 3px 5px 6px rgba(222, 222, 222, 1);
    background-color: rgba(82, 79, 79, 0.03);
    z-index: 10;
  }
  25% {
    box-shadow: 3px 3px 5px 6px rgba(222, 222, 222, 0.5);
    background-color: rgba(82, 79, 79, 0.024);
    z-index: 8;
  }
  50% {
    box-shadow: 3px 3px 5px 6px rgba(222, 222, 222, 0.25);
    background-color: rgba(82, 79, 79, 0.015);
    z-index: 5;
  }
  100% {
    box-shadow: 3px 3px 5px 6px rgba(222, 222, 222, 0);
    z-index: 1;
  }
}
