html {
  font-size: 12px; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

.navbar {
  padding: 0; }

.card-subtitle {
  color: #c5c4c4; }

.card-img-top {
  width: 100%;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.card-top {
  position: relative;
  width: 100;
  height: 100px;
  overflow: hidden; }

.card {
  overflow: hidden; }

.sidebar {
  display: none; }

.center-banner {
  text-align: center;
  margin-top: 15px; }

.App {
  text-align: center; }

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none; }

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; }

.App-link {
  color: #61dafb; }

.article-previews {
  width: 100%;
  padding: 20px; }

.article-previews article {
  height: 300px;
  background-color: black;
  display: grid;
  grid-template-rows: 40% 60%; }

.article-previews article span {
  background-color: white; }

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.navbar-brand img {
  max-width: 290px; }

#carouselHomeControls {
  height: 400px; }

.carousel-inner {
  max-height: 400px; }

.carousel {
  position: relative;
  max-height: 400px; }

.carousel-caption {
  bottom: 0;
  top: 10%; }

.card-top {
  height: 130px;
  border-bottom: solid thin #f6f8f6; }

.card-text {
  max-height: 3em;
  overflow: hidden; }

#secondary-page-heading {
  grid-area: heading;
  background-color: #00a6d7;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 40px solid black;
  position: relative;
  width: 100%;
  display: flex;
  height: 217px; }
  #secondary-page-heading h1 {
    color: white;
    justify-self: center;
    align-self: center;
    z-index: 100;
    font-size: 3em;
    margin-left: 3rem; }
  #secondary-page-heading h2 {
    color: white;
    justify-self: center;
    align-self: center;
    z-index: 100;
    font-size: 3em;
    margin-left: 3rem; }
  #secondary-page-heading img {
    position: absolute;
    z-index: 10;
    width: 100%;
    height: auto;
    max-height: 200px;
    min-height: 200px; }
  #secondary-page-heading .bg-overlay {
    position: absolute;
    width: 100%;
    height: 200px;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 20; }

.main-container {
  display: grid;
  width: 100%;
  background-color: #f6f8f6;
  grid-template-columns: 4fr 1fr;
  grid-template-areas: "heading heading" "body-content sidebar" "adspace sidebar" "foot foot"; }

#primary-page-heading {
  grid-area: heading;
  width: 100%; }

.main-body {
  grid-area: body-content;
  padding-left: 0;
  padding-right: 0; }
  .main-body .main-body-textarea {
    margin: 0 auto;
    width: 90%;
    margin-top: 20px; }
    .main-body .main-body-textarea h2 {
      font-size: 1.4rem; }
    .main-body .main-body-textarea p {
      font-size: 0.7rem; }

.sidebar {
  border: 1px solid black; }

.side-item {
  width: 98%;
  height: 40px;
  background-color: #f6f8f6;
  border-bottom: #000 solid 1px; }

.side-item:nth-of-type(even) {
  background-color: #e3e5e3; }

.sidebar ul {
  list-style: none;
  margin: 0;
  padding: 0; }

li.side-item .main-body {
  border: 1px solid black; }

.stub {
  background-color: red; }

.button {
  display: inline-block;
  zoom: 1;
  /* zoom and *display = ie7 hack for display:inline-block */
  *display: inline;
  vertical-align: baseline;
  margin: 0 2px;
  outline: none;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  font: 14px/100% Arial, Helvetica, sans-serif;
  padding: 0.5em 2em 0.55em;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  border-radius: 0.2em;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2); }

.blue {
  color: #d9eef7;
  border: solid 1px #0076a3;
  background: #bce0ff;
  background: -webkit-gradient(linear, left top, left bottom, from(#bce0ff), to(#0078a5));
  background: -moz-linear-gradient(top, #00adee, #0078a5);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00adee', endColorstr='#0078a5'); }

.blue:hover {
  background: #007ead;
  background: -webkit-gradient(linear, left top, left bottom, from(#0095cc), to(#00678e));
  background: -moz-linear-gradient(top, #0095cc, #00678e);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0095cc', endColorstr='#00678e'); }

.blue:active {
  color: #80bed6;
  background: -webkit-gradient(linear, left top, left bottom, from(#0078a5), to(#00adee));
  background: -moz-linear-gradient(top, #0078a5, #00adee);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0078a5', endColorstr='#00adee'); }

footer {
  height: 80px;
  background-color: #000; }

.slide-btn {
  position: relative;
  z-index: 100; }

/* image styles*/
.img-showcase-full {
  max-width: 80%;
  margin: 1em auto;
  display: block; }

.tech_article {
  margin: 0 0 4em 0; }

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-width: 320px) {
  /* Styles */
  .card {
    margin-top: 20px; }
  #page-heading-section {
    height: 200px; }
  h2 {
    font-size: 12px; } }

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-width: 768px) {
  /* Styles */ }

/* iPads (landscape) ----------- */
@media only screen and (min-width: 992px) {
  /* Styles */ }

/* iPads (portrait) ----------- */
@media only screen and (min-width: 768px) {
  /* Styles */
  html {
    font-size: 14px; } }

/* Desktops and laptops ----------- */
@media only screen and (min-width: 1200px) {
  /* Styles */
  html {
    font-size: 22px; }
  .main-body {
    /*TODO: Double check size old:min-width:1024px*/
    min-height: 1200px; }
  .sidebar {
    display: inline; }
  h5 {
    font-size: 1.1rem; }
  h6 {
    font-size: 0.8rem; }
  p {
    font-size: 0.7rem; }
  .btn {
    font-size: 0.9rem; }
  .card-subtitle {
    margin-bottom: 0.6rem; } }

.navbar {
  padding: 0; }

.navbar-brand {
  padding-top: 0;
  padding-bottom: 0; }

.navbar-expand-md .navbar-collapse {
  flex-direction: row-reverse; }

@media (min-width: 768px) {
  .navbar-expand-md .navbar-collapse {
    margin-right: 20px; } }

.navbar-nav a {
  font-size: 1.1rem; }

@media (min-width: 1200px) {
  .navbar-nav a {
    font-size: 0.8rem; } }

.list-link {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  padding: 0;
  margin: 0; }

.list-group-item {
  font-size: 2rem; }

.two-list {
  width: 50%; }

.link-group3 {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0; }
  .link-group3 li {
    width: 45%;
    height: 200px;
    border-radius: 10px;
    margin: 10px auto; }
  .link-group3 .list-group-item {
    z-index: 1;
    -webkit-animation-name: shadowAninOut;
            animation-name: shadowAninOut;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    overflow: hidden;
    background-color: rgba(82, 79, 79, 0.03); }

.link-group {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0; }
  .link-group li {
    width: 33.33%;
    height: 200px;
    border-radius: 10px; }
  .link-group h4 {
    font-size: 1rem;
    border-bottom: rgba(34, 48, 240, 0.308) dashed 1px; }
  .link-group .list-group-item p {
    font-size: 0.7rem;
    width: 100%; }
  .link-group .list-group-item {
    z-index: 1;
    -webkit-animation-name: shadowAninOut;
            animation-name: shadowAninOut;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    overflow: hidden;
    background-color: rgba(82, 79, 79, 0.03); }

.list-group-item p {
  font-size: 0.7rem;
  width: 50%; }

.list-group-item:hover {
  z-index: 10;
  -webkit-animation-name: shadowAnin;
          animation-name: shadowAnin;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-duration: 1s;
          animation-duration: 1s; }

.list-group-item h4 {
  font-size: 1rem; }

#page-heading-section {
  height: 400px;
  background-color: #00a6d7;
  display: flex;
  align-items: center;
  justify-content: center; }

h2 {
  color: black; }

.black-box-div {
  width: 300px;
  height: 100%;
  background-color: black; }

.clear-box-div {
  width: 100px;
  height: 100%; }

/* #### Mobile Phones Portrait #### */
@media screen and (max-device-width: 480px) and (orientation: portrait) {
  /* some CSS here */ }

/* #### Mobile Phones Landscape #### */
@media screen and (max-device-width: 640px) and (orientation: landscape) {
  /* some CSS here */
  #page-heading-section {
    height: 200px; }
  h2 {
    font-size: 12px; } }

/* #### Mobile Phones Portrait or Landscape #### */
@media screen and (max-device-width: 640px) {
  /* some CSS here */ }

/* #### iPhone 4+ Portrait or Landscape #### */
@media screen and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
  /* some CSS here */ }

/* #### Tablets Portrait or Landscape #### */
@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  /* some CSS here */ }

/* #### Desktops #### */
@media screen and (min-width: 1024px) {
  /* some CSS here */ }

@-webkit-keyframes shadowAnin {
  0% {
    box-shadow: 3px 3px 5px 6px rgba(222, 222, 222, 0);
    background-color: rgba(40, 201, 250, 0.02); }
  25% {
    box-shadow: 3px 3px 5px 6px rgba(222, 222, 222, 0.25);
    background-color: rgba(40, 201, 250, 0.04); }
  50% {
    box-shadow: 3px 3px 5px 6px rgba(222, 222, 222, 0.5);
    background-color: rgba(40, 201, 250, 0.06); }
  100% {
    box-shadow: 3px 3px 5px 6px #dedede;
    background-color: rgba(40, 201, 250, 0.09); } }

@keyframes shadowAnin {
  0% {
    box-shadow: 3px 3px 5px 6px rgba(222, 222, 222, 0);
    background-color: rgba(40, 201, 250, 0.02); }
  25% {
    box-shadow: 3px 3px 5px 6px rgba(222, 222, 222, 0.25);
    background-color: rgba(40, 201, 250, 0.04); }
  50% {
    box-shadow: 3px 3px 5px 6px rgba(222, 222, 222, 0.5);
    background-color: rgba(40, 201, 250, 0.06); }
  100% {
    box-shadow: 3px 3px 5px 6px #dedede;
    background-color: rgba(40, 201, 250, 0.09); } }

@-webkit-keyframes shadowAninOut {
  0% {
    box-shadow: 3px 3px 5px 6px #dedede;
    background-color: rgba(82, 79, 79, 0.03);
    z-index: 10; }
  25% {
    box-shadow: 3px 3px 5px 6px rgba(222, 222, 222, 0.5);
    background-color: rgba(82, 79, 79, 0.024);
    z-index: 8; }
  50% {
    box-shadow: 3px 3px 5px 6px rgba(222, 222, 222, 0.25);
    background-color: rgba(82, 79, 79, 0.015);
    z-index: 5; }
  100% {
    box-shadow: 3px 3px 5px 6px rgba(222, 222, 222, 0);
    z-index: 1; } }

@keyframes shadowAninOut {
  0% {
    box-shadow: 3px 3px 5px 6px #dedede;
    background-color: rgba(82, 79, 79, 0.03);
    z-index: 10; }
  25% {
    box-shadow: 3px 3px 5px 6px rgba(222, 222, 222, 0.5);
    background-color: rgba(82, 79, 79, 0.024);
    z-index: 8; }
  50% {
    box-shadow: 3px 3px 5px 6px rgba(222, 222, 222, 0.25);
    background-color: rgba(82, 79, 79, 0.015);
    z-index: 5; }
  100% {
    box-shadow: 3px 3px 5px 6px rgba(222, 222, 222, 0);
    z-index: 1; } }

/* Events Page*/
.evts-bg {
  background-image: url(/static/media/events_bg.bd78e4bc.jpg); }

.contact-bg {
  background-image: url(/static/media/contact_bg.90c246b6.jpg); }

.resource-bg {
  background-image: url(/static/media/resources_bg.eab2342d.jpg); }

.about-bg {
  background-image: url(/static/media/about_bg.85998d99.jpg); }

.film-bg {
  background-image: url(/static/media/events_bg.bd78e4bc.jpg); }

.bg-img {
  background-position: center;
  background-size: cover; }

/*animations*/
@-webkit-keyframes shadowAnin2 {
  0% {
    box-shadow: 3px 3px 5px 6px rgba(222, 222, 222, 0);
    background-color: rgba(82, 79, 79, 0.01); }
  25% {
    box-shadow: 3px 3px 5px 6px rgba(222, 222, 222, 0.25);
    background-color: rgba(82, 79, 79, 0.015); }
  50% {
    box-shadow: 3px 3px 5px 6px rgba(222, 222, 222, 0.5);
    background-color: rgba(82, 79, 79, 0.024); }
  100% {
    box-shadow: 3px 3px 5px 6px #dedede;
    background-color: rgba(82, 79, 79, 0.03); } }
@keyframes shadowAnin2 {
  0% {
    box-shadow: 3px 3px 5px 6px rgba(222, 222, 222, 0);
    background-color: rgba(82, 79, 79, 0.01); }
  25% {
    box-shadow: 3px 3px 5px 6px rgba(222, 222, 222, 0.25);
    background-color: rgba(82, 79, 79, 0.015); }
  50% {
    box-shadow: 3px 3px 5px 6px rgba(222, 222, 222, 0.5);
    background-color: rgba(82, 79, 79, 0.024); }
  100% {
    box-shadow: 3px 3px 5px 6px #dedede;
    background-color: rgba(82, 79, 79, 0.03); } }

@-webkit-keyframes shadowAninOut2 {
  0% {
    box-shadow: 3px 3px 5px 6px #dedede;
    background-color: rgba(82, 79, 79, 0.03);
    z-index: 10; }
  25% {
    box-shadow: 3px 3px 5px 6px rgba(222, 222, 222, 0.5);
    background-color: rgba(82, 79, 79, 0.024);
    z-index: 8; }
  50% {
    box-shadow: 3px 3px 5px 6px rgba(222, 222, 222, 0.25);
    background-color: rgba(82, 79, 79, 0.015);
    z-index: 5; }
  100% {
    box-shadow: 3px 3px 5px 6px rgba(222, 222, 222, 0);
    z-index: 1; } }

@keyframes shadowAninOut2 {
  0% {
    box-shadow: 3px 3px 5px 6px #dedede;
    background-color: rgba(82, 79, 79, 0.03);
    z-index: 10; }
  25% {
    box-shadow: 3px 3px 5px 6px rgba(222, 222, 222, 0.5);
    background-color: rgba(82, 79, 79, 0.024);
    z-index: 8; }
  50% {
    box-shadow: 3px 3px 5px 6px rgba(222, 222, 222, 0.25);
    background-color: rgba(82, 79, 79, 0.015);
    z-index: 5; }
  100% {
    box-shadow: 3px 3px 5px 6px rgba(222, 222, 222, 0);
    z-index: 1; } }

#contact-form {
  display: flex;
  flex-direction: column;
  margin: 80px;
  width: 500px; }
  #contact-form input[type="text"] {
    height: 30px;
    border: 1px solid #6db1ff;
    border-radius: 10px;
    width: 500px;
    margin-bottom: 20px;
    padding: 20px 10px; }
  #contact-form textarea {
    border: 1px soild blue;
    border-radius: 10px;
    width: 500px;
    padding: 20px 10px;
    border: 1px solid #6db1ff; }
  #contact-form input[type="submit"] {
    width: 150px;
    margin-top: 20px;
    font-size: 18px;
    color: black;
    align-self: flex-end; }

.selection-filter {
  font-size: 1em;
  width: 40%;
  margin: 20px 10px; }

.flex-main-body {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap; }

.flex-group {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  justify-content: space-evenly; }
  .flex-group .flex-group-items {
    flex-grow: 1;
    flex-basis: auto;
    display: grid;
    grid-template-columns: 33% 66%; }
  .flex-group .flex-img {
    grid-column-start: 1; }
  .flex-group .flex-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%; }
  .flex-group .flex-group-text {
    grid-column-start: 2;
    padding: 0.75rem 1rem;
    overflow: hidden; }
  .flex-group li {
    width: 28%;
    height: 200px;
    position: relative;
    display: block;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 10px;
    -webkit-animation-name: shadowAninOut;
    animation-name: shadowAninOut;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    overflow: hidden; }
  .flex-group li:hover {
    z-index: 10;
    -webkit-animation-name: shadowAnin;
    animation-name: shadowAnin;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-duration: 1s;
    animation-duration: 1s; }
  .flex-group h4 {
    font-size: 1rem;
    border-bottom: rgba(34, 48, 240, 0.308) dashed 1px; }
  .flex-group .flex-group-items p {
    font-size: 0.7rem;
    width: 100%; }

.load-text {
  width: auto;
  margin: 0;
  padding: 0;
  font-size: 40px; }

.load-text span {
  opacity: 1;
  width: 0;
  overflow: hidden;
  display: inline-block;
  margin: 0;
  padding: 0;
  position: relative;
  top: 0.25em;
  -webkit-animation: example 3s linear infinite;
          animation: example 3s linear infinite; }

@-webkit-keyframes example {
  0% {
    width: 0; }
  25% {
    width: 0.4em; }
  50% {
    width: 0.6em; }
  100% {
    width: 0.8em; } }

@keyframes example {
  0% {
    width: 0; }
  25% {
    width: 0.4em; }
  50% {
    width: 0.6em; }
  100% {
    width: 0.8em; } }

.link-group4 {
  list-style: none;
  justify-content: space-around;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0; }
  .link-group4 h2 {
    font-size: 1rem;
    padding: 30px 30px;
    vertical-align: bottom; }
  .link-group4 .link-heading {
    width: 100%;
    height: auto;
    border-radius: 0;
    border-top: 1px solid grey;
    border-bottom: 1px solid grey;
    margin: 20px 0;
    text-align: center; }
  .link-group4 .list-group-item {
    min-width: 300px;
    max-width: 500px;
    width: 33%;
    flex-grow: 2;
    flex-shrink: 1;
    overflow: hidden;
    height: 200px;
    margin-bottom: 10px; }
  .link-group4 .list-group-item p {
    margin: 0;
    width: 100%; }

.side-item {
  width: 100%;
  height: 60px;
  background-color: #f6f8f6;
  border-bottom: #000 solid 1px;
  padding: 14px;
  font-size: 1.1em; }

.side-item:hover {
  background-color: #5ea1e4;
  border-bottom: #000 solid 1px; }

.sidebar {
  grid-area: sidebar;
  border-top: none; }

footer {
  background-color: #00a4d6;
  height: 200px;
  grid-area: foot;
  display: flex;
  flex-flow: row wrap; }

.footer-ad {
  flex-basis: 100%;
  height: auto;
  width: 80%;
  margin: 2em auto;
  grid-area: adspace;
  text-align: center; }

footer ul {
  display: flex;
  flex-direction: row;
  justify-self: start;
  flex-grow: 100;
  width: 100%; }

footer ul li {
  display: flex; }

footer ul li a {
  font-size: 1em;
  color: white; }

footer ul li a.navlink {
  color: black; }

@media (min-width: 1200px) {
  footer ul li a {
    font-size: 0.8em;
    color: white; }
  .copyInfo {
    color: white;
    font-size: 0.6rem;
    margin: 0 auto; } }

